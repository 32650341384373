import { country, language } from './settings.ts';

const fetchCartPhraseIfNeeded = () => {
    if (window?.Checkout?.text) {
        return Promise.resolve();
    }
    const path = `https://www.ikea.com/${country}/${language}/shoppingcart/data/phrase/${language}-${country.toUpperCase()}.json`;
    return fetch(path)
        .then(response => response.json())
        .then(text => {
            if (!window.Checkout) {
                window.Checkout = {};
            }
            window.Checkout.text = text;
        })
        .catch(err => console.error('Can´t get translations file', err));
};

export default fetchCartPhraseIfNeeded;
