import { country } from './settings.ts';

export const getCookie = (cookieName: string) => {
    if (typeof document === 'undefined') {
        return '';
    }
    const cookie = document.cookie
        .split(';')
        .find(cookie => cookie.trim().startsWith(cookieName))
        ?.split('=');
    if (!cookie || cookie.length < 2) {
        return '';
    }
    return cookie[1];
};

export const createCookie = (
    key: string,
    value: string,
    domain = '',
    exp = 30
) => {
    const date = new Date();
    date.setTime(date.getTime() + exp * 24 * 60 * 60 * 1000);
    const expires = `;expires=${date.toUTCString()}`;
    const _domain = domain ? `;domain=${domain}` : '';
    document.cookie = `${key}=${value}${expires}${_domain};path=/`; // No Sonar
};

export const updateHeaderBadge = (quantity: number) => {
    const countryCode = country.toUpperCase();
    const stagingHosts = ['www.ppe.ikeadt.com', 'www.cte.ikeadt.com'];
    const host = new URL(window.location.href).host;
    const domain = stagingHosts.includes(host) ? '.ikeadt.com' : '.ikea.com';
    createCookie(`IRMW_CART_COUNT_${countryCode}`, String(quantity), domain);
    const event = new Event('addtocart', { bubbles: true, cancelable: true });
    document.body.dispatchEvent(event);
};
