import { type JwtPayload, jwtDecode } from 'jwt-decode';

export type Token = {
    value: string;
    expirationTime: number;
};
export type Tokens = {
    auth?: Token;
    guest?: Token;
};
export type AuthenticationState = {
    tokens: Tokens;
    currentToken: string;
    isLoggedIn: boolean;
};

type ProfileType = 'business' | 'individual' | '';

export type UserDetails = {
    isLoggedIn: boolean;
    profileType: ProfileType;
    isFamily?: boolean;
};

const isExpiredOrInvalidToken = (accessToken: string) => {
    if (accessToken) {
        const decodedToken = jwtDecode<JwtPayload>(accessToken);
        return Number(decodedToken.exp) < new Date().getTime() / 1000;
    }
    return true;
};

export const getTokenDetails = (accessToken?: string): UserDetails | null => {
    if (accessToken) {
        const decodedToken = jwtDecode<
            JwtPayload & 'partyUId' & 'customerType'
        >(accessToken);
        const partyUIdKey = Object.keys(decodedToken).find(key =>
            key.includes('partyUId')
        );
        const profileTypeKey = Object.keys(decodedToken).find(key =>
            key.includes('customerType')
        );
        const profileType: ProfileType = profileTypeKey
            ? decodedToken[profileTypeKey]
            : '';
        const partyUID = partyUIdKey ? decodedToken[partyUIdKey] : undefined;

        return {
            profileType,
            isLoggedIn: !!partyUID,
        };
    }
    return null;
};

const isTokenValid = (value: string) => {
    if (isExpiredOrInvalidToken(value)) {
        //TODO: show a toast?
        return false;
    }
    return true;
};

// Prefers logged in tokens, falls back to guest token
// Check for the validity of both guest and auth token
const pickToken = (tokens: Tokens) =>
    tokens.auth && isTokenValid(tokens.auth.value)
        ? tokens.auth.value
        : tokens.guest && isTokenValid(tokens.guest.value)
          ? tokens.guest.value
          : '';

export const getToken = () => {
    return new Promise<string>((resolve, reject) => {
        return window?.ikea?.authentication?.getTokens(
            (error: unknown, tokens: Tokens) => {
                if (error) {
                    //TODO: show a toast?
                    return reject(error);
                }
                // Checks for current token not to be invalid or null
                if (!pickToken(tokens)) {
                    return reject();
                }
                return resolve(pickToken(tokens));
            }
        );
    });
};
