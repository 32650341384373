import { getCart } from '../apis/cart';
import type { CartAgentType, CartData, MinimalCartItem } from '../types.ts';
import { appStorage } from './appStorage.ts';
import { reportError } from './sentry';
import { country } from './settings.ts';

type CartPayload = {
    cartItems: MinimalCartItem[];
};

type Callback = (data: CartPayload) => void;

export const CART_ITEMS_STORAGE_KEY = `cart-state-${country}`;
export const CART_DATA_STORAGE_KEY = `cart-data-${country}`;

const cartListeners: Callback[] = [];

export const subscribeCart = (callback: Callback) => {
    cartListeners.push(callback);
    const cartItems = getCartItemsFromCache();
    if (cartItems) {
        callback({ cartItems });
    }
};

export const getCartItemsFromCache = (): null | Array<MinimalCartItem> => {
    try {
        const stored = appStorage(CART_ITEMS_STORAGE_KEY);
        if (stored === null) {
            return null;
        }
        return stored.length ? JSON.parse(stored) : [];
    } catch (e) {
        reportError(e as Error);
    }
    return null;
};

export const getCartDataFromCache = (): null | CartData => {
    try {
        const storedData = appStorage(CART_DATA_STORAGE_KEY);
        if (storedData === null) {
            return null;
        }
        return JSON.parse(storedData);
    } catch (e) {
        reportError(e as Error);
        return null;
    }
};

export const fetchValidCartItemsData = async (): Promise<
    Array<MinimalCartItem>
> => {
    const storedData = getCartItemsFromCache();
    if (storedData === null) {
        const { cart } = await getCart();
        return renderMinimalCartItems(cart);
    }
    return Promise.resolve(storedData);
};

export const fetchValidCartData = async (): Promise<CartData> => {
    const storedData = getCartDataFromCache();
    if (storedData === null) {
        const { cart } = await getCart();
        return transformCartToStoreFormat(cart);
    }
    return Promise.resolve(storedData);
};
const renderMinimalCartItems = (
    cart: CartAgentType
): Array<MinimalCartItem> => {
    return (
        cart?.items?.map(item => ({
            itemNo: item.itemNo,
            name: item.product.name ?? '',
            images: item.product.images,
            quantity: item.quantity,
            type: item.itemType,
            pricePerItemGA: item.pricePerItemGA,
        })) ?? []
    );
};

export const transformCartToStoreFormat = (cart: CartAgentType) => {
    const cartItems = renderMinimalCartItems(cart);

    const cartData: CartData = {
        cart_id: cart.cart_id,
        cartItems,
        packageWeight: cart.packageWeight,
        regularTotalPrice: {
            amount: cart.regularTotalPrice.amount ?? 0,
        },
    };

    return cartData;
};

export const updateCartStore = (cartData: CartData) => {
    const cartItems = cartData.cartItems;

    appStorage(CART_ITEMS_STORAGE_KEY, JSON.stringify(cartItems));
    appStorage(CART_DATA_STORAGE_KEY, JSON.stringify(cartData));

    cartListeners.forEach(cb => cb({ cartItems }));
};

export const clearCartStore = () => {
    appStorage(CART_ITEMS_STORAGE_KEY, null);
};
