const NAMESPACE = 'CART_CLIENT';

export const AGENT_LOADED = `${NAMESPACE}/AGENT_LOADED`;

// Cart Item Events
export const ADD_TO_CART = `${NAMESPACE}/ADD_TO_CART`;
export const ADD_TO_CART_SUCCESS = `${NAMESPACE}/ADD_TO_CART_SUCCESS`;
export const ADD_TO_CART_FAIL = `${NAMESPACE}/ADD_TO_CART_FAIL`;
export const REMOVE_FROM_CART = `${NAMESPACE}/REMOVE_FROM_CART`;
export const REMOVE_FROM_CART_INITIATE = `${NAMESPACE}/REMOVE_FROM_CART_INITIATE`;
export const REMOVE_FROM_CART_SUCCESS = `${NAMESPACE}/REMOVE_FROM_CART_SUCCESS`;
export const REMOVE_FROM_CART_FAIL = `${NAMESPACE}/REMOVE_FROM_CART_FAIL`;
export const UPDATE_CART_ITEM_QUANTITY = `${NAMESPACE}/UPDATE_CART_ITEM_QUANTITY`;
export const UPDATE_CART_ITEM_QUANTITY_INITIATE = `${NAMESPACE}/UPDATE_CART_ITEM_QUANTITY_INITIATE`;
export const UPDATE_CART_ITEM_QUANTITY_SUCCESS = `${NAMESPACE}/UPDATE_CART_ITEM_QUANTITY_SUCCESS`;
export const UPDATE_CART_ITEM_QUANTITY_FAIL = `${NAMESPACE}/UPDATE_CART_ITEM_QUANTITY_FAIL`;

// General Cart Events
export const ADD_TO_CART_INITIATE = `${NAMESPACE}/ADD_TO_CART_INITIATE`;
export const UPDATE_CART = `${NAMESPACE}/CART_UPDATED`;

// Update header badge
export const UPDATE_CART_COUNT = `${NAMESPACE}/UPDATE_CART_COUNT`;

export const SHOW_REC_MODAL = `${NAMESPACE}/SHOW_REC_MODAL`;

// Events triggered from Cart when cart is updated
export const UPDATE_DATA = 'TROJAN_AGENT/UPDATE_DATA';

export const publish = window.ikea.pubsub.publish;
