import {
    agentPath,
    allowCookies,
    env,
    sentryClient,
    sentryKey,
    sentryProject,
    version,
} from './settings';

const sentryEnabled =
    window.ikea?.sentry &&
    sentryKey &&
    sentryProject &&
    sentryClient &&
    version &&
    allowCookies;

if (sentryEnabled) {
    const regex = new RegExp(agentPath, '');
    window.ikea.sentry.register(
        sentryProject,
        sentryKey,
        sentryClient,
        version,
        1, // Log 100% of errors for now
        [regex]
    );
} else {
    console.warn('[cart-agent] Sentry is not active');
}

export const reportError = (e: Error | string) => {
    if (sentryEnabled) {
        window.ikea.sentry.store(
            e,
            sentryProject,
            sentryKey,
            sentryClient,
            version,
            1
        );
    }
    if (env !== 'production') {
        console.error(e);
    }
};
