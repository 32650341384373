export interface CustomError {
    code: string;
    data?: {
        itemNos?: Array<string>;
    } & unknown;
}
export class CartBackendError extends Error {
    errors: Array<CustomError> = [];
    reference = '';
    items: Array<{ itemNo: string; description: string }> = [];
    constructor(
        message: string,
        errors: Array<CustomError>,
        items?: Array<{ itemNo: string; description: string }>,
        reference?: string
    ) {
        super(message);
        this.name = 'CartBackendError';
        this.errors = errors;
        this.items = items ?? [];
        this.reference = reference ?? '';
    }
}
